@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?nq0a2q');
  src:  url('fonts/icomoon.eot?nq0a2q#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?nq0a2q') format('truetype'),
    url('fonts/icomoon.woff?nq0a2q') format('woff'),
    url('fonts/icomoon.svg?nq0a2q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e91a";
}
.icon-instagram:before {
  content: "\e91b";
}
.icon-x:before {
  content: "\e91c";
}
.icon-remote-control:before {
  content: "\e95b";
}
.icon-vintage-tv:before {
  content: "\e95c";
}
.icon-rocket:before {
  content: "\e95a";
}
.icon-dislike-filled:before {
  content: "\e958";
}
.icon-like-filled:before {
  content: "\e959";
}
.icon-dislike:before {
  content: "\e950";
}
.icon-like:before {
  content: "\e957";
}
.icon-rename:before {
  content: "\e94d";
}
.icon-create-new:before {
  content: "\e94e";
}
.icon-remove:before {
  content: "\e955";
}
.icon-eye-close:before {
  content: "\e94f";
}
.icon-eye:before {
  content: "\e91f";
}
.icon-face-sad:before {
  content: "\e951";
}
.icon-movie-filled:before {
  content: "\e952";
}
.icon-movie:before {
  content: "\e953";
}
.icon-not-intrested:before {
  content: "\e954";
}
.icon-share-2:before {
  content: "\e956";
}
.icon-video-play:before {
  content: "\e94a";
}
.icon-money-fill:before {
  content: "\e945";
}
.icon-calendar-outline-rounded:before {
  content: "\e946";
}
.icon-round-view-list:before {
  content: "\e947";
}
.icon-round-shield:before {
  content: "\e948";
}
.icon-credit-card-2:before {
  content: "\e949";
}
.icon-search-off:before {
  content: "\e944";
}
.icon-mb-icon-2:before {
  content: "\e942";
}
.icon-select-arrows:before {
  content: "\e941";
}
.icon-check-circle:before {
  content: "\e932";
}
.icon-child-care:before {
  content: "\e933";
}
.icon-chrome-reader-mode:before {
  content: "\e934";
}
.icon-closed-caption:before {
  content: "\e935";
}
.icon-desktop:before {
  content: "\e936";
}
.icon-dvr:before {
  content: "\e937";
}
.icon-filter-3:before {
  content: "\e938";
}
.icon-star-solid-2:before {
  content: "\e939";
}
.icon-square-arrow-right:before {
  content: "\e93a";
}
.icon-loyalty:before {
  content: "\e93b";
}
.icon-my-location:before {
  content: "\e93c";
}
.icon-people:before {
  content: "\e93d";
}
.icon-location:before {
  content: "\e93e";
}
.icon-play-circle-filled:before {
  content: "\e93f";
}
.icon-dollar-sign:before {
  content: "\e931";
}
.icon-triangle:before {
  content: "\e92e";
}
.icon-rectangle:before {
  content: "\e92f";
}
.icon-circle:before {
  content: "\e930";
}
.icon-movie-stars:before {
  content: "\e900";
}
.icon-compass-filled:before {
  content: "\e901";
}
.icon-coin:before {
  content: "\e902";
}
.icon-bookmark:before {
  content: "\e903";
}
.icon-slider:before {
  content: "\e915";
}
.icon-vertical-slash:before {
  content: "\e925";
}
.icon-linkedin:before {
  content: "\e91d";
}
.icon-help:before {
  content: "\e920";
}
.icon-link:before {
  content: "\e921";
}
.icon-play:before {
  content: "\e922";
}
.icon-share:before {
  content: "\e923";
}
.icon-close2:before {
  content: "\e919";
}
.icon-arrow-left:before {
  content: "\e917";
}
.icon-check2:before {
  content: "\e918";
}
.icon-file-text:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-bag:before {
  content: "\e906";
}
.icon-check:before {
  content: "\e907";
}
.icon-chevron-down:before {
  content: "\e908";
}
.icon-chevron-right:before {
  content: "\e909";
}
.icon-chevron-left:before {
  content: "\e926";
}
.icon-close:before {
  content: "\e90a";
}
.icon-heart:before {
  content: "\e90b";
}
.icon-loader:before {
  content: "\e90c";
}
.icon-lock:before {
  content: "\e90d";
}
.icon-minus:before {
  content: "\e90e";
}
.icon-plus:before {
  content: "\e90f";
}
.icon-search:before {
  content: "\e910";
}
.icon-star-solid:before {
  content: "\e911";
}
.icon-trash:before {
  content: "\e912";
}
.icon-unlock:before {
  content: "\e913";
}
.icon-box:before {
  content: "\e914";
}
.icon-thumbs-up:before {
  content: "\e916";
}
.icon-correct-symbol:before {
  content: "\e924";
}
.icon-star:before {
  content: "\e927";
}
.icon-chrome:before {
  content: "\e928";
}
.icon-textsms:before {
  content: "\e929";
}
.icon-sms:before {
  content: "\e929";
}
.icon-airplay:before {
  content: "\e92a";
}
.icon-compass:before {
  content: "\e92b";
}
.icon-delete:before {
  content: "\e92c";
}
.icon-apple:before {
  content: "\e94b";
}
.icon-google:before {
  content: "\e94c";
}
.icon-faq:before {
  content: "\e943";
}
.icon-whatsapp:before {
  content: "\e92d";
}
.icon-envelope:before {
  content: "\e940";
}
